import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
      return ['accountDropdownToggle']
    }
    connect() {
    }

    accountDropdown() {
      this.accountDropdownToggleTarget.classList.toggle('d-block')
    }
}

