import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  editQuantity(event) {
    if (event.key === 'Enter') {
      let updated_quantity = event.currentTarget.firstElementChild.value
      event.currentTarget.firstElementChild.blur()
      this.updateQuantity(this.urlValue, updated_quantity)
    }
  }

  async updateQuantity(url, updated_quantity) {
    const request = new FetchRequest('patch', url, {
      body: JSON.stringify({quantity: updated_quantity}),
      contentType: "application/json",
    })
    const response = await request.perform()
    if (response.ok) {
      this.flashMessage()
    }
  }

  flashMessage() {
    this.flashMessageController.showMessage("Quantity has been updated")
  }

  get flashMessageController() {
    return this.application.controllers.find(controller => {
      return controller.context.identifier === 'flash_message'
    })
  }
}