import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ['selectedUserOptions', 'apiKeyField', 'emailField', 'storeUrlField', 'storeTypeField']
    }

    connect() {
    }

    selectMerchant() {
        let selectedStoreRecord = this.selectedUserOptionsTarget.options[this.selectedUserOptionsTarget.selectedIndex]
        this.apiKeyFieldTarget.value = selectedStoreRecord.getAttribute("api-key")
        this.storeUrlFieldTarget.value = (selectedStoreRecord.getAttribute("api-url")) ? selectedStoreRecord.getAttribute("api-url") : '-'
        this.storeTypeFieldTarget.value = selectedStoreRecord.getAttribute("api-type")
    }
}