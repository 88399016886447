import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static get targets() {
        return ['toggleMenu', 'menuWidth']
    }

    connect() {
    }

    leftMenuToggle() {
        this.toggleMenuTarget.classList.toggle('show')
        this.menuWidthTarget.classList.toggle('toggle-side-bar')
    }
}
