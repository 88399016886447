import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flashMessage", 'flashMessageWraper']

  close() {
    this.flashMessageTarget.remove()
  }

  showMessage(message) {
    this.flashMessageWraperTarget.innerHTML = `<div data-flash_message-target="flashMessage" class="flash-message-div"  id="quantity-status">
      <div class="alert alert-primary alert-dismissible fade show" role="alert">
        ${message}
        <button data-action="click->flash_message#close" type="button" class="btn-close" aria-label="Close"></button>
      </div>
    </div>`
  }
}